import React, { Component } from 'react';

import { Card, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { MdDeleteForever } from 'react-icons/md';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import AuthInfoHolder from 'app/auth/AuthService';
import RegionPicker from 'app/views/components/region/RegionPicker';
import i18next from 'i18next';

import { warning, validIpAddressV4 } from '../../../components/notifications/NotificationHandler';

import { createVPC } from './CreateVPCService';

class CreatePrivateNetwork extends Component {
  state = {
    selectedProjectId: AuthInfoHolder.getCurrentlySelectedProjectId(),
    regionsList: [],
    selectedRegionId: null,
    createVPCInProgress: false,
    nameVPC: null,
    subnetCIDR: null,
    dnsInputField: null,
    dnsAdded: [],
  };

  handleCreateVPC = () => {
    const validationErrorTimeout = 3300;
    const CIDRformat =
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(3[0-2]|[1-2][0-9]|[0-9])?)$/;

    let withErrors = false;

    if (this.state.nameVPC === null) {
      NotificationManager.warning(
        i18next.t('Please fill in the VPC Name'),
        '',
        validationErrorTimeout,
        () => {},
        1,
      );
      withErrors = true;
    }

    if (this.state.subnetCIDR === null) {
      NotificationManager.warning(
        i18next.t('Please fill in the Subnet CIDR'),
        '',
        validationErrorTimeout,
        () => {},
        1,
      );
      withErrors = true;
    } else if (this.state.subnetCIDR.match(CIDRformat) === null) {
      NotificationManager.warning(
        i18next.t('The Subnet CIDR is incorrect'),
        '',
        validationErrorTimeout,
        () => {},
        1,
      );
      withErrors = true;
    }

    if (!withErrors) {
      this.setState({ createVPCInProgress: true });
      createVPC(
        this.state.selectedProjectId,
        this.state.selectedRegionId,
        this.state.nameVPC,
        false,
        this.state.subnetCIDR,
        this.state.dnsAdded,
      )
        .then((res) => {
          console.log('createVPC result: ', res.data);
          this.setState({ vpcData: res.data });
          this.props.history.push('/networking?tab=privatenetwork');
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  onRegionChange = (regionId) => {
    this.setState({
      selectedRegionId: regionId,
    });
  };

  handleDeleteDNS = (item) => {
    let modifyArr = [...this.state.dnsAdded];
    modifyArr.splice(item, 1);
    this.setState({ dnsAdded: modifyArr });

    console.log('delete => ', this.state.dnsAdded);
  };

  handleAddDNS = () => {
    let dnsNameserversList = [...this.state.dnsAdded];

    const isExist = dnsNameserversList.includes(this.state.dnsInputField);
    if (isExist) {
      return warning('This address already exist!!!');
    }
    const isValidIpV4 = validIpAddressV4(this.state.dnsInputField);

    if (this.state.dnsInputField !== null) {
      if (!isValidIpV4) {
        return warning('Please enter valid ipV4 address!');
      } else {
        dnsNameserversList.push(this.state.dnsInputField);
      }
    }

    this.setState({ dnsAdded: dnsNameserversList });
  };

  render() {
    return (
      <div>
        <Link to="/networking?tab=privatenetwork" className="btn-back">
          <ReactSVG src="/assets/icons/arrowleft.svg" className="mr-2 nav-icon"></ReactSVG>
          {i18next.t('Back to Private Networks')}
        </Link>
        <Card elevation={6} className="w-100 section-create-instance">
          <div className="container-fluid">
            <div className="pt-0 pb-3 row justify-content-between section-wrapper">
              <RegionPicker
                selectedProjectId={this.state.selectedProjectId}
                onRegionChange={this.onRegionChange}
              />
            </div>
            <div className="py-3 row justify-content-between section-wrapper">
              <div className="area-title">2. {i18next.t('Private Network Name')}</div>
              <div className="col-12">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <input
                      ref={(input) => {
                        this.nameInput = input;
                      }}
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        this.setState({ nameVPC: event.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3 row justify-content-between section-wrapper">
              <div className="area-title">3. {i18next.t('Subnet CIDR')}</div>
              <div className="col-12">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        this.setState({ subnetCIDR: event.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row py-3 justify-content-between section-wrapper">
              <div className="area-title">4. {i18next.t('Dns Name Servers (Optional)')}</div>
              <div className="col-12">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <input
                      ref={(input) => {
                        this.nameInput = input;
                      }}
                      type="text"
                      className="form-control"
                      onChange={(e) => this.setState({ dnsInputField: e.target.value })}
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <button className="btn btn-primary" onClick={() => this.handleAddDNS()}>
                      {i18next.t('Add')}
                    </button>
                  </div>
                  <div className="row-sm-6 col-md-12 ">
                    {this.state.dnsAdded.length > 0 && (
                      <div className="flex-row nav nav-pills">
                        {this.state.dnsAdded.map((item, index) => {
                          return (
                            <div className="nav-item">
                              <div
                                key={index}
                                className="mr-0 card-picker d-flex align-items-center"
                              >
                                <div className="body-info">
                                  <p className="m-0 title">{item}</p>
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="tooltip-pass-req">Delete</Tooltip>}
                                >
                                  <div className="cursor-pointer">
                                    <MdDeleteForever
                                      className="d-block pt-2 font-weight-bold popover-vminstance-flavor-icon i-Network-Window hover "
                                      onClick={() => this.handleDeleteDNS(index)}
                                    />
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3 row justify-content-center">
              <div className="col-12">
                <div className="pb-3 row">
                  <div className="text-left col-12">
                    <i className="float-left mr-1 text-20 i-Information"></i>{' '}
                    {i18next.t('You are about to create new Non-Routable Private Network')}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="text-left col-sm-6 col-md-3">
                    {this.state.createVPCInProgress === false && (
                      <button className="btn btn-primary" onClick={() => this.handleCreateVPC()}>
                        {i18next.t('Create')}
                      </button>
                    )}

                    {this.state.createVPCInProgress === true && (
                      <div className="justify-content-center">
                        <ProgressBar
                          key={100}
                          now={100}
                          animated
                          striped
                          variant={'primary'}
                          className="mb-1"
                        ></ProgressBar>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <NotificationContainer />
      </div>
    );
  }
}

export default CreatePrivateNetwork;
