import React from 'react';

import { Redirect } from 'react-router-dom';

import AuthGuard from './auth/AuthGuard';
import appMarketplace from './views/appmarketplace/appMarketplaceRoutes';
import backupsRoutes from './views/backups/BackupsRoutes';
import cdn from './views/cdn/cdnRoutes';
import dashboardRoutes from './views/dashboard/dashboardRoutes';
import dedicatedServerRoutes from './views/dedicatedservers/dedicatedServerRoutes';
import dnsmanagementRoutes from './views/dnsmanagement/dnsmanagementRoutes';
import firewallsRoutes from './views/firewalls/firewallsRoutes';
import loadBalancerRoutes from './views/loadbalancer/loadBalancerRoutes';
import networkingRoutes from './views/networking/NetworkingRoutes';
import objectStorageRoutes from './views/objectstorage/objectStorageRoutes';
import pagesRoutes from './views/pages/pagesRoutes';
import sessionsRoutes from './views/sessions/sessionsRoutes';
import storageVolumesRoutes from './views/storagevolumes/storageVolumesRoutes';
import usageAndBilling from './views/usageandbilling/usageAndBillingRoutes';
import userManagement from './views/usermanagement/userManagementRoutes';
import vmInstancesRoutes from './views/vminstances/vmInstancesRoutes';
import k8sRoutes from './views/k8s/k8sRoutes';

const redirectRoute = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...sessionsRoutes,
  {
    path: '/',
    component: AuthGuard,
    routes: [
      ...dashboardRoutes,
      ...vmInstancesRoutes,
      ...dedicatedServerRoutes,
      ...storageVolumesRoutes,
      ...firewallsRoutes,
      ...backupsRoutes,
      ...objectStorageRoutes,
      ...networkingRoutes,
      ...loadBalancerRoutes,
      ...dnsmanagementRoutes,
      ...k8sRoutes,
      ...cdn,
      ...appMarketplace,
      ...usageAndBilling,
      ...userManagement,
      ...pagesRoutes,
      ...redirectRoute,
      ...errorRoute,
    ],
  },
];

export default routes;
