import axios from 'axios';

export const getRegions = (onlyK8sEnabled, cancelToken) => {
  return axios.get(
    `${window.Configs.publicApiBaseUrl}/regions${onlyK8sEnabled ? '?k8s_only=true' : ''}`,
    {
      cancelToken,
    },
  );
};
