import React, { Component, Suspense } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppContext from 'app/appContext';
import { setLayoutSettings, setDefaultSettings } from 'app/redux/actions/LayoutActions';
import { PropTypes } from 'prop-types';

import { OmniCloudLayouts } from '.';
import Customizer from './SharedComponents/Customizer';

class OmniCloudLayout extends Component {
  state = {};

  componentDidMount() {
    this.initAppDirection();
  }

  initAppDirection = () => {
    let { settings } = this.props;
    setTimeout(() => {
      document.documentElement.setAttribute('dir', settings.dir);
    });
  };

  render() {
    let { activeLayout, route, hasAllPermissions, settings } = this.props;
    let Layout = OmniCloudLayouts[activeLayout];
    return (
      <Suspense>
        <Layout routes={route.routes} hasAllPermissions={hasAllPermissions} />
        {settings.customizer.show && <Customizer />}
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  settings: state.layout.settings,
  activeLayout: state.layout.settings.activeLayout,
  defaultSettings: state.layout.defaultSettings,
});

OmniCloudLayout.contextType = AppContext;

export default withRouter(
  connect(mapStateToProps, { setLayoutSettings, setDefaultSettings })(OmniCloudLayout),
);
