import { lazy } from 'react';

import { authRoles } from 'app/auth/authRoles';

const AppMarketplace = lazy(() => import('./AppMarketplace'));
const AppDetails = lazy(() => import('./AppDetails'));
const AppCreationRequested = lazy(() => import('./AppCreationRequested'));

const pagesRoutes = [
  {
    path: '/app-marketplace/:app_id',
    component: AppDetails,
    auth: authRoles.admin,
  },
  {
    path: '/app-marketplace',
    component: AppMarketplace,
    auth: authRoles.admin,
  },
  {
    path: '/appstack/:stackId/under-deployment',
    component: AppCreationRequested,
    auth: authRoles.admin,
  },
];

export default pagesRoutes;
