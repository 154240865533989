import axios from 'axios';

export const getProjects = (cancelToken) => {
  return axios.get(`${window.Configs.publicApiBaseUrl}/projects`, {
    cancelToken,
  });
};

export const createVPC = (
  projectId,
  physicalRegionId,
  networkName,
  is_routable,
  subnetCidr,
  dnsAdded,
  cancelToken,
) => {
  return axios.post(
    `${window.Configs.publicApiBaseUrl}/projects/${projectId}/physical-regions/${physicalRegionId}/networks`,
    {
      name: networkName,
      is_routable: is_routable,
      subnet_cidr: subnetCidr,
      dns_nameservers: dnsAdded,
    },
    { cancelToken },
  );
};
