import { lazy } from 'react';

import { authRoles } from 'app/auth/authRoles';

const UsageAndBilling = lazy(() => import('./UsageAndBilling'));

const pagesRoutes = [
  {
    path: '/usageandbilling',
    component: UsageAndBilling,
    auth: authRoles.admin,
    required_permissions: ['billing_read'],
  },
];

export default pagesRoutes;
