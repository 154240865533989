let keycloak = null;
let initialAccessToken = null;
let initialRefreshToken = null;

const KeycloakHolder = {
  setInitialAccessAndRefreshTokens: (accessToken, refreshToken) => {
    initialAccessToken = accessToken;
    initialRefreshToken = refreshToken;
  },
  getInitialAccessToken: () => {
    return initialAccessToken;
  },
  getInitialRefreshToken: () => {
    return initialRefreshToken;
  },
  getKeycloak: () => {
    if (keycloak == null) {
      keycloak = window.Keycloak('/keycloak.json');
    }
    return keycloak;
  },
  getKeycloakToken: () => {
    keycloak
      .updateToken(30)
      .then((refreshed) => {
        if (refreshed) {
          console.log('THE TOKEN HAS BEEN UPDATED');
        } else {
          console.log('STILL USING THE OLD TOKEN');
        }
      })
      .catch((err) => {
        console.log('ERROR UPDATING TOKEN', err);
      });

    return keycloak.token;
  },
};

Object.freeze(KeycloakHolder);

export default KeycloakHolder;
