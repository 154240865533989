import React from 'react';

import { useSelector } from 'react-redux';

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { MuiDarkCustomTheme } from './darkTheme';
import { MuiCustomTheme } from './theme';

export const ThemeProvider = ({ children }) => {
  const settings = useSelector((state) => state.layout.settings);
  return (
    <MuiThemeProvider theme={settings.isDarkMode ? MuiDarkCustomTheme : MuiCustomTheme}>
      <CssBaseline />
      <React.Fragment>{children}</React.Fragment>
    </MuiThemeProvider>
  );
};
