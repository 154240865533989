import AuthInfoHolder from 'app/auth/AuthService';

export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';

export const setSelectedProjectId = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_PROJECT,
    data: data,
  });
  AuthInfoHolder.setSelectedProjectId(data);
};
