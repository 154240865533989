import { createTheme } from '@mui/material';

export const MuiDarkCustomTheme = createTheme({
  fonts: {
    primary: 'Roboto',
    baseSize: '14px',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#305dd2',
    },
    common: {
      white: '#ffffff',
      black: '#091726',
    },
    backgrounds: {
      card: '#121826',
    },
    border: {
      main: '#2d3748',
    },
    labels: {
      title: '#6285e3',
      heading: 'rgb(237, 242, 247)',
    },
    charts: {
      gradientBaseColor: 'rgb(255,255,0)',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
    h2: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
    h3: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '130%',
      letterSpacing: '0px',
    },
    h4: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '140%',
      letterSpacing: '0px',
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '160%',
      letterSpacing: '0px',
    },
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '160%',
      letterSpacing: '0px',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
    button: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily:
            '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,Cantarell, "Helvetica Neue", sans-serif',
        },
        '& .MuiPaper-root': {
          '&.MuiDialog-paper': {
            background: '#1b2032',
            border: '1px solid #2d3748 !important',
          },
        },
      },
    },
  },
});
