import { lazy } from 'react';

const Error404 = lazy(() => import('./Error'));

const SetCredentials = lazy(() => import('./SetCredentials'));

const Impersonate = lazy(() => import('./Impersonate'));

const sessionsRoutes = [
  {
    path: '/session/404',
    component: Error404,
  },
  {
    path: '/user-management/set-credentials/:invitation_guid',
    component: SetCredentials,
  },
  {
    path: '/impersonate',
    component: Impersonate,
  },
];

export default sessionsRoutes;
