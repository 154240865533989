import { lazy } from 'react';

import { authRoles } from 'app/auth/authRoles';

const LoadBalancer = lazy(() => import('./LoadBalancer'));
const LoadbalancerCreate = lazy(() => import('./createlb/LoadbalancerCreate'));
const LbDetails = lazy(() => import('./lbdetails/LbDetails'));

const pagesRoutes = [
  {
    path: '/loadbalancers/list',
    component: LoadBalancer,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_read'],
  },
  {
    path: '/loadbalancers/create',
    component: LoadbalancerCreate,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_write'],
  },
  {
    path: '/loadbalancers/:project_id/:region_id/:instance_id',
    component: LbDetails,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_read'],
  },
];

export default pagesRoutes;
