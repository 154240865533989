import { lazy } from 'react';

import { authRoles } from 'app/auth/authRoles';

import CreatePrivateNetwork from './vpc/createVPC/CreatePrivateNetwork';

const Networking = lazy(() => import('./Networking'));
const CreateVPC = lazy(() => import('./vpc/createVPC/CreateVPC'));
const CreateFloatingIP = lazy(() => import('./floatingIPs/createFloatingIP/CreateFloatingIP'));

const pagesRoutes = [
  {
    path: '/networking/vpc/create',
    component: CreateVPC,
    auth: authRoles.admin,
  },
  {
    path: '/networking/private-network/create',
    component: CreatePrivateNetwork,
    auth: authRoles.admin,
  },
  {
    path: '/networking/floatingIP/create',
    component: CreateFloatingIP,
    auth: authRoles.admin,
  },
  {
    path: '/networking',
    component: Networking,
    auth: authRoles.admin,
  },
];

export default pagesRoutes;
