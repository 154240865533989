import React, { Suspense } from 'react';

import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';

import history from '@history';
import { Loading } from '@omnicloud';

import '../styles/app/app.scss';
import '../styles/custom/style.scss';
import { ThemeProvider } from '../theme/ThemeProvider';

import routes from './RootRoutes';
import RootRoutes from './RootRoutes';
import AppContext from './appContext';
import { Store } from './redux/Store';

function App() {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <ThemeProvider>
          <Suspense fallback={<Loading />}>
            <Router history={history}>{renderRoutes(RootRoutes)}</Router>
          </Suspense>
        </ThemeProvider>
      </Provider>
      <NotificationContainer />
    </AppContext.Provider>
  );
}

export default App;
