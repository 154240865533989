import { lazy } from 'react';

import { authRoles } from 'app/auth/authRoles';

const DedicatedServers = lazy(() => import('./DedicatedServers'));

const DedicatedServerDetails = lazy(() =>
  import('./dedicatedServerDetails/DedicatedServerDetails'),
);

const pagesRoutes = [
  {
    path: '/dedicated-servers/list',
    component: DedicatedServers,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_read'],
  },
  {
    path: '/dedicated-servers/:dedicated_server_id',
    component: DedicatedServerDetails,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_read'],
  },
];

export default pagesRoutes;
