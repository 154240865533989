import layoutSettings from './Layout/LayoutSettings';

export const OmniCloudLayoutSettings = {
  activeLayout: 'layout',
  dir: 'ltr', // ltr, rtl
  layoutSettings,
  customizer: {
    show: false,
    open: true,
  },
  footer: {
    show: true,
  },
  isDarkMode: localStorage.getItem('theme') === 'dark' ? true : false,
};
