import { lazy } from 'react';
import React from 'react';

import { authRoles } from 'app/auth/authRoles';

const K8sClusters = lazy(() => import('./K8sClusters'));
const K8sClustersCreate = lazy(() => import('./K8sClustersCreate'));
const K8sClusterDetails = lazy(() => import('./clusters/K8sClusterDetails'));
const K8sClusterNode = lazy(() => import('./clusters/K8sClusterNode'))

const pagesRoutes = [
  {
    path: '/k8s/list',
    component: K8sClusters,
    auth: authRoles.admin,
  },
  {
    path: '/k8s/create',
    component: K8sClustersCreate,
    auth: authRoles.admin,
  },
  {
    path: '/k8s/:project_id/:physical_region_id/:cluster_id/:node_id',
    component: K8sClusterNode,
    auth: authRoles.admin,
  },
  {
    path: '/k8s/:project_id/:physical_region_id/:cluster_id',
    component: K8sClusterDetails,
    auth: authRoles.admin,
  },
];

export default pagesRoutes;
