import { createTheme } from '@mui/material';

export const MuiCustomTheme = createTheme({
  fonts: {
    primary: 'Roboto',
    baseSize: '14px',
  },
  palette: {
    primary: {
      main: '#305dd2',
    },
    common: {
      white: '#ffffff',
      black: '#091726',
    },
    backgrounds: {
      card: '#ffffff',
    },
    border: {
      main: '#e9eff4',
    },
    labels: {
      title: 'rgb(18, 24, 40)',
      heading: '#181c3d',
    },
    charts: {
      gradientBaseColor: 'rgb(49, 93, 209)',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
    h2: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
    h3: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '130%',
      letterSpacing: '0px',
    },
    h4: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '140%',
      letterSpacing: '0px',
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '160%',
      letterSpacing: '0px',
    },
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '160%',
      letterSpacing: '0px',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
    button: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '120%',
      letterSpacing: '0px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily:
            '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,Cantarell, "Helvetica Neue", sans-serif',
        },
      },
    },
  },
});
