import axios from 'axios';

export const getAllCustomerAccounts = (keycloakAccessToken) => {
  return axios.get(window.Configs.publicApiBaseUrl + '/customer-accounts', {
    headers: {
      Authorization: `Bearer ${keycloakAccessToken}`,
    },
  });
};

export const getCustomerSpecificToken = (customerAccountId, keycloakAccessToken) => {
  return axios.get(
    window.Configs.publicApiBaseUrl + `/customer-accounts/${customerAccountId}/token`,
    {
      headers: {
        Authorization: `Bearer ${keycloakAccessToken}`,
      },
    },
  );
};

let customerAccounts = [];
let customerAccountId = null;
let customerSpecificToken = null;
let permissions = [];
let projects = [];
let selectedProjectId = null;

const AuthInfoHolder = {
  getCustomerAccounts: () => customerAccounts,
  setCustomerAccounts: (newVal) => {
    customerAccounts = newVal;
    selectedProjectId = null;
  },
  getCustomerAccount: () => customerAccounts.find((cu) => cu['id'] == customerAccountId),
  getCustomerAccountId: () => customerAccountId,
  setCustomerAccountId: (newVal) => (customerAccountId = newVal),
  getCustomerSpecificToken: () => customerSpecificToken,
  setCustomerSpecificToken: (newVal) => (customerSpecificToken = newVal),
  getPermissions: () => permissions,
  setPermissions: (newVal) => (permissions = newVal),
  getProjects: () => projects,
  getCurrentlySelectedProjectId: () => {
    let response = AuthInfoHolder.getSelectedProjectId();

    if (response === null && projects != null && projects.length > 0) {
      // no project was selected so far, so we will return the first one
      return projects[0]['projectId'];
    }

    return response;
  },
  setSelectedProjectId: (newVal) => {
    // sanity check: check if the newVal is actually a project that we have access to
    let matchingProject = projects.find((p) => p.projectId == newVal);
    if (matchingProject !== undefined) {
      selectedProjectId = newVal;
    } else {
      selectedProjectId = null;
    }

    // save the value within the localStorage as well
    localStorage.setItem('project', selectedProjectId);

    return selectedProjectId;
  },
  getSelectedProjectId: () => {
    if (selectedProjectId === null) {
      // no selectedProject so far, so let's check if we have something within the localStorage
      // if we have it, then we check if the projectId from the localStorage is actually a project that we have access to
      // if so, then this is what we should be using
      let localStorageProjectId = localStorage.getItem('project');
      if (localStorageProjectId !== 'null') {
        let matchingProject = projects.find((p) => p.projectId == localStorageProjectId);
        if (matchingProject !== undefined) {
          return localStorageProjectId;
        }
      }
    }
    return selectedProjectId;
  },
  setProjects: (newVal) => (projects = newVal),
};

export default AuthInfoHolder;
