import { combineReducers } from 'redux';

import LayoutReducer from './LayoutReducer';
import ProjectReducer from './ProjectReducer';

const RootReducer = combineReducers({
  layout: LayoutReducer,
  project: ProjectReducer,
});

export default RootReducer;
