import { lazy } from 'react';

import { authRoles } from 'app/auth/authRoles';

const StorageVolumes = lazy(() => import('./StorageVolumes'));
const StorageVolumeDetails = lazy(() => import('./instancedetails/StorageVolumeDetails'));
const VolumeCreate = lazy(() => import('./createvolume/VolumeCreate'));

const pagesRoutes = [
  {
    path: '/storagevolumes/list',
    component: StorageVolumes,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_read'],
  },
  {
    path: '/storagevolumes/:project_id/:physical_region_id/:volume_id',
    component: StorageVolumeDetails,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_read'],
  },
  {
    path: '/volume/create',
    component: VolumeCreate,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_write'],
  },
];

export default pagesRoutes;
