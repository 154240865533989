import React, { Component } from 'react';

import { Tab, Nav } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';

import { getRegions } from 'app/services/region';
import axios from 'axios';
import { COUNTRY_TO_FLAG } from 'common/constants';
import i18next from 'i18next';

class RegionPicker extends Component {
  state = {
    regionsList: [],
    loading: true,
  };

  getAllRegions = () => {
    const onlyK8sEnabled =
      this.props.onlyK8sEnabled != null && this.props.onlyK8sEnabled === true ? true : false;
    getRegions(onlyK8sEnabled, this.axiosCancelSource.token)
      .then((res) => {
        const sortedRegions = res.data.regions.sort((a, b) =>
          a.geo_region_name > b.geo_region_name
            ? 1
            : a.geo_region_name === b.geo_region_name
            ? a.country > b.country
            : a.country === b.country
            ? a.name > b.name
            : -1,
        );

        const groupedRegions = sortedRegions.reduce((acc, value) => {
          if (!acc[value.geo_region_name]) {
            acc[value.geo_region_name] = [];
          }

          acc[value.geo_region_name].push(value);

          return acc;
        }, {});

        this.setState({ regionsList: groupedRegions });
        this.setState({ loading: false });
        this.props.onRegionChange(groupedRegions[Object.keys(groupedRegions)[0]][0].id);
      })
      .catch((err) => {
        //...
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source();
    this.getAllRegions();
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel('Component unmounted.');
  }

  render() {
    let regionsList = this.state.regionsList;

    return (
      <div id="region" className="section-wrapper w-100">
        <p className="mb-2 section-title">{i18next.t('Choose Location')}</p>
        {this.state.loading && (
          <div className="d-flex justify-content-center">
            <div className="text-center loader-bubble loader-bubble-primary m-5"></div>
          </div>
        )}
        {!this.state.loading && (
          <Tab.Container defaultActiveKey={regionsList?.[Object.keys(regionsList)[0]]?.[0]?.id}>
            <Nav variant="pills" className="flex-row">
              {Object.keys(regionsList).length > 0 &&
                Object.keys(regionsList).map((geoRegionName) =>
                  regionsList[geoRegionName].map((region) => {
                    return (
                      <Nav.Item key={region.id}>
                        <Nav.Link
                          eventKey={region.id}
                          onClick={() => this.props.onRegionChange(region.id)}
                        >
                          <div className="card-picker d-flex align-items-center">
                            <div className="card-picker-selector d-flex align-items-center">
                              <img
                                src={`/assets/images/countries/${COUNTRY_TO_FLAG[region.country]}`}
                                alt={region.country + ' Flag'}
                              />
                              <div className="body-info">
                                <p className="m-0 title">{region.name}</p>
                              </div>
                              <ReactSVG src="/assets/icons/CheckCircle.svg" className="check" />
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    );
                  }),
                )}
            </Nav>
          </Tab.Container>
        )}
      </div>
    );
  }
}

export default RegionPicker;
