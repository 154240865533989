import { lazy } from 'react';

import { authRoles } from 'app/auth/authRoles';

const VmInstances = lazy(() => import('./VmInstances'));

const VmInstanceDetails = lazy(() => import('./instancedetails/VmInstanceDetails'));

const VmInstanceCreate = lazy(() => import('./createinstance/VmInstanceCreate'));

const pagesRoutes = [
  {
    path: '/vminstances/list',
    component: VmInstances,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_read'],
  },
  {
    path: '/vminstances/:project_id/:region_id/:instance_id',
    component: VmInstanceDetails,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_read'],
  },
  {
    path: '/vminstances/create',
    component: VmInstanceCreate,
    auth: authRoles.admin,
    required_permissions: ['infrastructure_write'],
  },
];

export default pagesRoutes;
